.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 2;
  position: sticky;
}

.mainNavContainter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  border-bottom: 0.5px solid var(--primary-border);
}

.main {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  /* column-gap: 50px; */
  justify-content: space-between;
  /* height: 50px; */
  list-style: none;
  max-width: var(--max-width);

  padding: 0rem;
  margin-bottom: 0rem;
}

.subNavContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 1rem;
  top: 51.5px;
  padding: 2rem;
  background-color: white;
  width: 100%;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  max-width: var(--max-width);
}

.listItem {
  color: var(--text-main);
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
}

.listItem:hover {
  text-decoration: underline;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rightSection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 1rem;
  direction: rtl;
  margin-top: -1rem;
  list-style: none;
}

.categoriesList {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  padding: 0;
  margin: 0;
  list-style: none;
}
.subcategories {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  list-style: none;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.5rem;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 120px;
  height: fit-content;
  cursor: pointer;
  text-decoration: none;
  direction: ltr; /* Reset to left-to-right for grid items if needed */
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.img {
  max-height: inherit;
  height: inherit;
  width: inherit;
  border-radius: 4px;
  max-width: 120px;
  width: 120px;
  max-height: 120px;
  height: 120px;

  max-width: inherit;
  object-fit: cover;
  transition: filter 0.3s ease-in-out;
}

/*  */
.imgContainer:hover .img {
  filter: brightness(0.9);
}

.overlay {
  /* padding: 1rem; */
  /* top: 50%;
  transform: translateY(-50%);

  position: absolute; */
  padding-top: 0.75rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  row-gap: 0.25rem;
}

.text {
  color: var(--text-body);
  font-family: "Editorial";
  /* text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5); */
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0rem;
}

.textDiscover {
  color: var(--text-mute);
  /* text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5); */
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 0rem;
  text-decoration: underline;
}

.subHeader {
  font-size: 13px;
  text-transform: uppercase;
  color: var(--text-main);
  cursor: pointer;
  text-decoration: none;
}

.subHeader:hover {
  text-decoration: underline;
}

.linkItem {
  text-decoration: none;
}

.mainItem {
  font-size: 13px;
  text-transform: uppercase;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.mainItem .borderBottom {
  position: absolute;
  left: 50%; /* Start from the middle */
  bottom: 0;
  height: 1px;
  width: 0; /* Initial width, make it 0 to make the border grow from center */
  background-color: var(--text-main);
  z-index: 4;
  transition: width 0.3s, left 0.3s; /* Transition for width and left properties */
  transform: scaleX(0); /* Set initial scale to 0 to be invisible at first */
  transform-origin: center; /* Set the transform origin to the center */
}

.mainItem:hover .borderBottom {
  width: 80%; /* Full width on hover */
  left: 10%; /* Adjust left to accommodate full width */
  transform: scaleX(1); /* Scale to full size on hover */
  transition: transform 0.3s, width 0.3s, left 0.3s; /* Add transition for transform */
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.redText {
  color: #ba4444;
}

.regularText {
  color: var(--text-main);
}

.subCategoryList {
  display: none;
}

.optionsList {
  display: none;
}

/* Show subcategory list when the parent is hovered */
div:hover > .subCategoryList {
  display: block;
}

/* Show options list when the parent is hovered */
div:hover > .optionsList {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
