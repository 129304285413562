.wrapper {
  display: none;
}

.searchBarClass {
  height: 46px;
  width: 100%;
}

.navToggle {
  min-width: 46px;

  min-height: 46px;
  border: 1px solid var(--primary-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .wrapper {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    gap: 0.75rem;
  }
}
